<template>
  <div class="bg-white shadow rounded-lg pt-5 pl-5 mb-3">
    <div>
      <p class="font-semibold">
        {{ fullName }}
        <a
          role="button"
          @click="openPopUp"
          style="display: inline-block; float: right"
          class="mr-3"
        >
          <i class="vs-icon icon-scale vs-button--icon feather icon-edit"></i>
        </a>
        <a
          role="button"
          @click="openViewPopUp"
          style="display: inline-block; float: right"
          class="mr-3"
        >
          <i class="vs-icon icon-scale vs-button--icon feather icon-eye"></i>
        </a>
      </p>
    </div>
    <hr />
    <div class="flex gap-1 pb-2 mt-3">
      <p class="">{{ $tc("message.customerId", 1, this.countryCode) }}:</p>
      <div class="flex space-between">
        <p>
          {{ customer.simple_id }}
        </p>
      </div>
    </div>
    <div class="flex gap-1 pb-2">
      <p class="">{{ $tc("message.taxNumberId", 1, this.countryCode) }}:</p>
      <div class="flex space-between">
        <p>
          {{ customer.tax_identification_number }}
        </p>
      </div>
    </div>

    <div class="flex gap-1 pb-2">
      <p class="">{{ documentType }}:</p>
      <div class="flex space-between">
        <p>
          {{ customerIndividual.personal_identity_number }}
        </p>
      </div>
    </div>
    <div class="flex gap-1 pb-2">
      <p class="">Teléfono:</p>
      <div class="flex space-between">
        <p>
          {{ customer.phone }}
        </p>
      </div>
    </div>

    <div class="flex gap-1 pb-2">
      <p class="">Email:</p>
      <div class="flex space-between">
        <p>
          {{ customer.email }}
        </p>
      </div>
    </div>
    <vs-popup title="Actualización de cliente" :active.sync="clientUpdatePopUp">
      <UpdateClientForm
        :customer="customer"
        :customerIndividual="customerIndividual"
        :reservation="reservation"
        :updateFn="updateFn"
        :countryCode="countryCode"
      />
    </vs-popup>

    <vs-popup title="Vista de cliente" :active.sync="clientViewPopUp">
      <ViewClientForm
        :customer="customer"
        :customerIndividual="customerIndividual"
        :reservation="reservation"
        :updateFn="updateFn"
        :countryCode="countryCode"
      />
    </vs-popup>
  </div>
</template>

<script>
import UpdateClientForm from "./UpdateClientForm.vue";
import ViewClientForm from "./ViewClientForm.vue";
import { customerApi } from "../../customer/services";
import { capitalize } from "../../../helpers/capitalize";

export default {
  props: ["reservation", "customer"],
  components: {
    UpdateClientForm,
    ViewClientForm,
  },
  async mounted() {
    this.documentTypes = await customerApi.getDocumentPersonal();
  },
  data() {
    return {
      clientUpdatePopUp: false,
      clientViewPopUp: false,
      documentTypes: [],
    };
  },
  computed: {
    customerIndividual() {
      if (!this.customer) return null;

      return this.customer.customer_individual;
    },
    fullName() {
      if (!this.customer) return "--";

      return `${this.customerIndividual.first_name}
        ${this.customerIndividual.middle_name || ""}
        ${this.customerIndividual.first_surname}`;
    },
    countryCode() {
      return this.reservation.quote.project.city.country.code;
    },
    documentType() {
      const documentTypeId = this.customerIndividual.document_type_id;

      const findDocument = this.documentTypes.find(
        (document) => document.id === documentTypeId
      );

      if (!findDocument) {
        return this.$tc("message.personalIdentityNumber", 1, this.countryCode);
      }

      if (findDocument && documentTypeId == "PERSONAL_DOCUMENT_ID") {
        return this.$tc("message.personalIdentityNumber", 1, this.countryCode);
      } else {
        return capitalize(findDocument.name);
      }
    },
  },
  methods: {
    openPopUp() {
      this.clientUpdatePopUp = true;
    },
    openViewPopUp() {
      this.clientViewPopUp = true;
    },
    updateFn(value) {
      this.clientUpdatePopUp = !value;
    },
  },
};
</script>

<style lang="scss" scoped></style>