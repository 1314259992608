<template>
  <div>
    <div v-if="createDocumentFile">
      <FileImporter
        :onSuccess="onLoadPaymentFile"
        :buttonLabel="
          `Selecciona documento de ${$tc(
            'message.reservation',
            1,
            this.countryCode
          )}`
        "
      />
      <p class="text-red-600 mt-2 pb-2" v-if="error">{{ error }}</p>
      <vs-row>
        <vs-col class="sm:w-full lg:w-full p-1">
          <vs-button
            color="success"
            type="filled"
            ref="loadableButton"
            id="button-with-loading"
            class="vs-con-loading__container w-full mt-2"
            @click="uploadDocumentFile"
          >
            Cargar documento</vs-button
          >
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-full p-1">
          <vs-button
            color="danger"
            type="filled"
            class="w-full mt-1"
            @click="cancelReplace"
          >
            Cancelar</vs-button
          >
        </vs-col>
      </vs-row>
    </div>

    <div v-if="!createDocumentFile">
      <iframe
        :src="reservationDocumentFile"
        frameborder="0"
        class="w-full h-80"
      ></iframe>
      <vs-row>
        <vs-col class="sm:w-full lg:w-full p-1">
          <vs-button
            @click="replaceDocument"
            color="success"
            icon-pack="feather"
            icon="icon-save"
            type="filled"
            class="w-full mt-2"
          >
            Reemplazar</vs-button
          >
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>
import FileImporter from "@/components/FileImporter.vue";
import S3 from "aws-s3";
import slugify from "../../../helpers/slugify";
import { reservationApi } from "../services";
import { sentryCaptureException } from "../../../helpers/Sentry";

export default {
  props: ["reservation", "countryCode", "updateFn"],
  components: {
    FileImporter
  },
  mounted() {
    this.serializeDocument();
  },
  data() {
    return {
      reservationDocumentFile: null,
      createDocumentFile: false,
      error: ""
    };
  },
  watch: {
    reservation() {
      this.serializeDocument();
    }
  },
  computed: {
    logoDir() {
      const projectName = slugify(this.reservation.quote.project.name);
      const developerName = slugify(
        this.reservation.quote.project.property_developer.name
      );

      return `${developerName}/projects/${projectName}/gallery`;
    },
    S3Client() {
      return new S3({
        bucketName: "flattlo-app",
        dirName: this.logoDir,
        region: "us-east-1",
        accessKeyId: process.env.VUE_APP_AWS_ID,
        secretAccessKey: process.env.VUE_APP_AWS_SK,
        s3Url: process.env.VUE_APP_S3_URL
      });
    }
  },
  methods: {
    serializeDocument() {
      this.reservationDocumentFile = this.reservation.document_url || "";
      this.createDocumentFile = !this.reservationDocumentFile ? true : false;
    },
    onLoadPaymentFile(file) {
      this.reservationDocumentFile = file;
    },
    replaceDocument() {
      this.createDocumentFile = true;
      this.reservationDocumentFile = "";
    },
    cancelReplace() {
      this.createDocumentFile = false;
      this.reservationDocumentFile = this.reservation.document_url;
    },
    async uploadDocumentFile() {
      try {
        if (this.createDocumentFile && !this.reservationDocumentFile) {
          this.error = "Debes seleccionar un documento para poder continuar *";
          return;
        }

        this.$vs.loading({
          background: this.backgroundLoading,
          color: this.colorLoading,
          container: "#button-with-loading",
          scale: 0.5
        });

        const newFile = await this.S3Client.uploadFile(
          this.reservationDocumentFile,
          Date.now()
        );

        await reservationApi.upd(this.reservation.id, {
          document_url: newFile.location
        });

        this.$vs.notify({
          title: this.$tc("message.reservation", 1, this.countryCode),
          text: `Documento actualizado correctamente.`,
          color: "success"
        });

        this.$vs.loading.close("#button-with-loading > .con-vs-loading");
        this.createDocumentFile = false;
        this.reservationDocumentFile = "";

        this.updateFn(true);
        this.$root.$emit("update-reservation", {
          reservationId: this.reservation.id
        });
      } catch (e) {
        sentryCaptureException(e, "reservation");
        this.$vs.loading.close("#button-with-loading > .con-vs-loading");
        this.$vs.notify({
          title: `Error`,
          text: `Ha ocurrido al procesar documento de ${this.$tc(
            "message.reservation",
            1,
            this.countryCode
          )}.`,
          color: "danger"
        });
      }
    }
  }
};
</script>
