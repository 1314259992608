<template>
  <div>
    <div class="file-import">
      <input
        type="file"
        ref="fileInput"
        class="hidden"
        accept=".jpeg, .png, .jpg, .pdf"
        @change="handleClick"
      />
      <div
        @click="$refs.fileInput.click()"
        @drop="handleDrop"
        @dragover="handleDragover"
        @dragenter="handleDragover"
        class="px-8 py-16 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl"
      >
      <div class="flex justify-center mb-4">
         <feather-icon
          icon="UploadCloudIcon"
          svgClasses="h-36 w-36 stroke-current text-grey"
          class="block"
        />
      </div>

        <div>
          <vs-button @click.stop="$refs.fileInput.click()">{{buttonLabel}}</vs-button>
        </div>
        <h6 v-if="fileName" class="mt-2">Se ha seleccionado el archivo {{fileName}}</h6>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    onSuccess: {
      type: Function,
      required: true
    },
    buttonLabel: {
      type: String,
      default: "Arrastra tu archivo o haz click aquí"
    }
  },
  data() {
    return {
      fileName: null
    }
  },
  methods: {
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = "copy";
    },
    handleDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      const files = e.dataTransfer.files;
      if (files.length !== 1) {
        this.$vs.notify({
          title: "Intento de subida",
          text: "Por favor, unicamente subir un archivo!",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning"
        });
        return;
      }
      const rawFile = files[0]; // only use files[0]
      if (!this.acceptedFile(rawFile)) {
        this.$vs.notify({
          title: "Intento de subida",
          text: "Tipo de archivo no permitido.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning"
        });
        return false;
      }
      this.uploadFile(rawFile);
    },
    readerData(rawFile) {
      this.fileName = rawFile.name;
      this.onSuccess(rawFile);
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0];
      if (!rawFile) return;
      this.uploadFile(rawFile);
    },
    acceptedFile(file) {
      return /\.(jpeg|jpg|png|pdf)$/.test(file.name);
    },
    uploadFile(file) {
      this.$refs["fileInput"].value = null;
      this.readerData(file);
    }
  }
};
</script>