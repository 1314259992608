<template>
  <div>
    <div class="mt-4">
      <PortfolioResumeArea
        :reservation="reservation"
        :downpayments="downpayments"
        :seller="reservation.quote.user"
      />
    </div>
    <div v-if="downpayments.length" class="w-full mt-4">
      <ReservationDownpayments
        :downpayments="downpayments"
        :reservation="reservation"
      />
    </div>
    <div>
      <ModalUploadPayment v-show="modalPayment" />
    </div>
    <div>
      <ModalConfirmationPayment v-show="modalPaymentDetail" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ModalUploadPayment from "../components/ModalUploadPayment.vue";
import ModalConfirmationPayment from "../components/ModalConfirmationPayment.vue";
import PortfolioResumeArea from "../components/PortfolioResumeArea.vue";
import ReservationDownpayments from "../components/ReservationDownpayments.vue";
import { sentryCaptureException } from "../../../helpers/Sentry";

import reservationApi from "../services/reservation.api";
//import BreadcrumbComponent from '../../../layouts/components/BreadcrumbComponent.vue';

export default {
  components: {
    PortfolioResumeArea,
    ReservationDownpayments,
    ModalUploadPayment,
    ModalConfirmationPayment,
    //BreadcrumbComponent
  },
  data() {
    return {
      reservation: [],
      reservationId: null,
      downpayments: [],
    };
  },
  async beforeMount() {
    const { id } = this.$route.params;
    await this.reservationPayments(id);
  },
  async mounted() {
    this.$root.$on("reload-payments", async ({ reserveId }) => {
      await this.reservationPayments(reserveId);
    });

    this.$root.$on("update-reservation", async ({ reservationId }) => {
      this.reservation = await reservationApi.get(reservationId);
    });
  },
  computed: {
    ...mapState("reservation", ["modalPayment", "modalPaymentDetail"]),
  },
  methods: {
    async reservationPayments(reservationId) {
      try {
        this.downpayments = await reservationApi.getDowpayments(reservationId);
        this.reservation = await reservationApi.get(reservationId);
      } catch (e) {
        sentryCaptureException(e, "reservation-downpayment");
      }
    },
  },
};
</script>