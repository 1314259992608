<template>
  <div>
    <div class="bg-white shadow rounded-lg pt-5 pl-5 mb-3">
      <div>
        <p class="font-semibold">
          {{ business.name }}
          <a
            role="button"
            @click="openPopUp"
            style="display: inline-block; float: right"
            class="mr-3"
          >
            <i class="vs-icon icon-scale vs-button--icon feather icon-edit"></i>
          </a>
          <a
            role="button"
            @click="openViewPopUp"
            style="display: inline-block; float: right"
            class="mr-3"
          >
            <i class="vs-icon icon-scale vs-button--icon feather icon-eye"></i>
          </a>
        </p>
      </div>
      <hr />
      <div class="flex gap-1 pb-2 mt-3">
        <p class="">{{ $tc("message.taxNumberId", 1, this.countryCode) }}:</p>
        <div class="flex space-between">
          <p>
            {{ customer.tax_identification_number }}
          </p>
        </div>
      </div>

      <div class="flex gap-1 pb-2">
        <p class="">Teléfono:</p>
        <div class="flex space-between">
          <p>
            {{ customer.phone }}
          </p>
        </div>
      </div>

      <div class="flex gap-1 pb-2">
        <p class="">Email:</p>
        <div class="flex space-between">
          <p>
            {{ customer.email }}
          </p>
        </div>
      </div>
    </div>
    <vs-popup title="Actualización de cliente" :active.sync="clientUpdatePopUp">
      <vs-tabs>
        <vs-tab label="Información de persona jurídica">
          <div class="con-tab-ejemplo">
            <UpdateBusinessForm
              :customerBusiness="customer"
              :business="business"
              :customerIndividual="customerIndividual"
              :individual="individual"
              :reservation="reservation"
              :updateFn="updateFn"
              :countryCode="countryCode"
              :editable="editableForm"
            />
          </div>
        </vs-tab>
        <vs-tab label="Información de representante legal">
          <div class="con-tab-ejemplo">
            <UpdateLegalOwnerForm
              :customerBusiness="customer"
              :business="business"
              :customerIndividual="customerIndividual"
              :individual="individual"
              :reservation="reservation"
              :updateFn="updateFn"
              :countryCode="countryCode"
              :editable="editableForm"
            />
          </div>
        </vs-tab>
      </vs-tabs>
    </vs-popup>
    
  </div>
</template>

<script>
import { customerApi } from "../../customer/services";
import UpdateLegalOwnerForm from "./UpdateLegalOwnerForm.vue";
import UpdateBusinessForm from "./UpdateBusinessForm.vue";

export default {
  props: ["reservation", "customer"],
  async mounted() {
    this.documentTypes = await customerApi.getDocumentPersonal();
  },
  components: {
    UpdateLegalOwnerForm,
    UpdateBusinessForm
  },
  data() {
    return {
      clientUpdatePopUp: false,
      clientViewPopUp: false,
      documentTypes: [],
      editableForm: true
    };
  },
  computed: {
    individual() {
      if (!this.customer) return null;

      return this.customer.customer_business.customer_individual;
    },
    customerIndividual() {
      const [customer] = this.individual.customers;
      return customer;
    },
    business() {
      if (!this.customer) return null;

      return this.customer.customer_business;
    },
    countryCode() {
      return this.reservation.quote.project.city.country.code;
    },
  },
  methods: {
    openPopUp() {
      this.editableForm = true;
      this.clientUpdatePopUp = true;
    },
    openViewPopUp() {
      this.editableForm = false;
      this.clientUpdatePopUp = true;
    },
    updateFn(value) {
      this.clientUpdatePopUp = !value;
    },
  },
};
</script>

<style lang="scss" scoped></style>
