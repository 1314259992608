<template>
  <div>
    <div
      class="fixed z-10 inset-0 overflow-y-auto mt-24 md:mt-4"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-400 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
        >
          <div class="block absolute top-0 right-0 pt-4 pr-4">
            <button
              @click="toggleUploadPayment(false)"
              type="button"
              class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <span class="sr-only">Close</span>
              <!-- Heroicon name: outline/x -->
              <svg
                class="h-10 w-10 hover:text-danger"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div v-if="!moduleError">
            <div class="sm:flex sm:items-start">
              <div
                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10"
              >
                <!-- Heroicon name: outline/exclamation -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-blue-flattlo"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                  />
                </svg>
              </div>
              <div class="text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-2xl leading-6 font-medium text-gray-900 mt-2"
                  id="modal-title"
                >
                  Ingreso de pago
                </h3>
              </div>
            </div>
            <div>
              <fieldset>
                <legend class="sr-only">Pricing plans</legend>

                <div>
                  <div class="px-6 mt-4 md:w-2/3 w-full">
                    <label for="price" class="block text-xl text-gray-700"
                      >Monto:</label
                    >
                    <div class="mt-1 relative rounded-md shadow-sm">
                      <div
                        class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                      ></div>
                      <input
                        type="number"
                        name="price"
                        v-model.number="userPaymentAmount"
                        id="price"
                        class="focus:ring-blue-500 border text-xl focus:border-blue-500 block w-full pl-7 pr-12 border-gray-300 rounded-md h-12"
                        aria-describedby="price-currency"
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
              <div class="sm:col-span-2 px-6 mt-4">
                <p>Moneda del monto</p>
                <v-select
                  label="name"
                  :options="currencies"
                  v-model="selectedCurrency"
                ></v-select>
              </div>
              <div class="sm:col-span-2 px-6 mt-4">
                <p>Seleccione documento de soporte</p>
                <v-select
                  label="name"
                  :options="paymentMethod"
                  v-model="paymentMethodSelected"
                ></v-select>
              </div>
              <div class="px-6 mt-4 w-full">
                <label for="price" class="block text-xl text-gray-700"
                  >Número de referencia:</label
                >
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div
                    class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                  ></div>
                  <input
                    type="text"
                    name="price"
                    v-model.trim="documentReference"
                    id="price"
                    class="focus:ring-blue-500 border text-xl focus:border-blue-500 block w-full pl-7 pr-12 border-gray-300 rounded-md h-12"
                    aria-describedby="price-currency"
                  />
                </div>
              </div>
              <div class="px-6 mt-4 w-full">
                <label for="price" class="block text-xl text-gray-700"
                  >Fecha de Pago:</label
                >
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div
                    class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                  ></div>
                  <input
                    type="date"
                    name="price"
                    v-model.trim="paidOn"
                    id="price"
                    class="focus:ring-blue-500 border text-xl focus:border-blue-500 block w-full pl-7 pr-12 border-gray-300 rounded-md h-12"
                    aria-describedby="price-currency"
                  />
                </div>
              </div>
              <div class="sm:col-span-2 px-6 mt-4">
                <div class="flex justify-between">
                  <label
                    for="message"
                    class="block text-xl font-medium text-warm-gray-900"
                    >Descripción:</label
                  >
                </div>
                <div class="mt-1">
                  <textarea
                    id="message"
                    name="message"
                    v-model="paymentDescription"
                    rows="4"
                    class="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border border-warm-gray-300 rounded-md"
                    aria-describedby="message-max"
                  ></textarea>
                </div>
              </div>
              <div class="p-6">
                <FileImporter :onSuccess="onloadFile" />
              </div>
            </div>

            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                ref="loadableButton"
                id="button-with-loading"
                class="vs-con-loading__container w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-xl"
                @click="submitPayment"
                :disabled="!fieldsReady"
              >
                Ingresar pago
              </button>
              <button
                @click="toggleUploadPayment()"
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-xl"
              >
                Cancelar
              </button>
            </div>
          </div>

          <div
            v-if="moduleError"
            class="bg-red-400 text-center mx-10 mt-3 text-white"
          >
            <p class="font-semibold">
              Ha ocurrido un error al iniciar módulo y se ha notificado al
              equipo técnico, por favor intente más tarde.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import S3 from "aws-s3";
import vSelect from "vue-select";
import FileImporter from "../../../components/FileImporter.vue";
import projectApi from "../../projects/services/project.api";
import { reservationApi, paymentMethodApi } from "../services";
import { sentryCaptureException } from "../../../helpers/Sentry";
import slugify from "../../../helpers/slugify";

export default {
  components: {
    FileImporter,
    vSelect,
  },
  data() {
    return {
      project: null,
      paymentDescription: null,
      paymentDocumentFile: null,
      paymentMethod: [],
      currencies: [],
      selectedCurrency: null,
      paymentMethodSelected: null,
      documentReference: null,
      moduleError: false,
      paidOn: null,
    };
  },
  async mounted() {
    try {
      const projectId = this.$store.getters["project/currentProject"];
      
      this.project = await projectApi.get(projectId);
      const countryId = this.project.country_currency.country_id
      this.paymentMethod = await paymentMethodApi.list();
      this.currencies = (await paymentMethodApi.listCurrencies(countryId
)).map(function (
        currency
      ) {
        return {
          id: currency.currency.code,
          name: currency.currency.name,
        };
      });
      this.selectedCurrency = this.currencies[0];
      // console.log(this.selectedCurrency);
    } catch (e) {
      sentryCaptureException(e, "upload-payment");
      this.moduleError = true;
    }
  },
  computed: {
    ...mapState("reservation", ["reservationId", "payment", "paymentAmount"]),
    userPaymentAmount: {
      get() {
        return this.paymentAmount.toFixed(2);
      },
      set(value) {
        this.$store.commit("reservation/setPaymentAmount", parseFloat(value));
      },
    },
    fieldsReady() {
      if (
        this.paymentAmount &&
        this.paymentDocumentFile &&
        this.paymentMethodSelected
      ) {
        return true;
      }
      return false;
    },
    config() {
      const projectName = slugify(this.project.name);
      const developerName = slugify(this.project.property_developer.name);

      return {
        bucketName: "flattlo-app",
        dirName: `${developerName}/projects/${projectName}/payments`,
        region: "us-east-1",
        accessKeyId: process.env.VUE_APP_AWS_ID,
        secretAccessKey: process.env.VUE_APP_AWS_SK,
        s3Url: process.env.VUE_APP_S3_URL,
      };
    },
    S3Client() {
      return new S3(this.config);
    },
  },
  methods: {
    toggleUploadPayment(v) {
      this.paymentDescription = null;
      this.paymentDocumentFile = null;
      this.paymentMethodSelected = null;
      this.documentReference = null;
      this.paidOn = null;
      this.$store.commit("reservation/setModalPayment", v);
    },
    onloadFile(file) {
      this.paymentDocumentFile = file;
    },
    async submitPayment() {
      try {
        this.$vs.loading({
          background: this.backgroundLoading,
          color: this.colorLoading,
          container: "#button-with-loading",
          scale: 0.5,
        });

        const fileName = Date.now();
        const uploadImage = await this.S3Client.uploadFile(
          this.paymentDocumentFile,
          fileName
        );

        const payment = {
          amount: this.paymentAmount,
          description: this.paymentDescription ? this.paymentDescription : null,
          voucherUrl: uploadImage.location,
          paymentMethod: this.paymentMethodSelected.id,
          documentReference: this.documentReference
            ? this.documentReference
            : null,
          paidOn: this.paidOn,
          currencyCode: this.selectedCurrency.id,
        };

        await reservationApi.payReservationInstallment(
          this.reservationId,
          payment
        );
        this.$store.commit("reservation/setModalPayment", false);
        this.$root.$emit("reload-payments", { reserveId: this.reservationId });

        this.paymentDescription = null;
        this.paymentDocumentFile = null;
        this.paymentMethodSelected = null;
        this.documentReference = null;
        this.paidOn = null;
        this.$vs.loading.close("#button-with-loading > .con-vs-loading");
      } catch (e) {
        this.$vs.loading.close("#button-with-loading > .con-vs-loading");
        sentryCaptureException(e, "upload-payment");
        this.$vs.notify({
          title: `Error`,
          text: `Ha ocurrido un almacenar pago.`,
          color: "danger",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>