<template>
  <v-app>
    <div>
      <v-stepper>
        <v-stepper-header>
          <template v-for="(item, index) in status" v-if="reservation.status !== 'LOST'">
            <v-stepper-step
              :key="index"
              :step="index + 1"
              :complete="reservationStatus.length > index"
              :editable="reservationStatus.length <= index"
              @click="
                reservationStatus.length <= index
                  ? changeReservationStatus(item.id)
                  : null
              "
            >
              {{ item.name }}
              <small>{{ reservationStatusDate(item.id) }}</small>
            </v-stepper-step>
            <v-divider :key="index"></v-divider>
          </template>
        </v-stepper-header>
      </v-stepper>
    </div>
  </v-app>
</template>
<script>
import reservationApi from "../services/reservation.api";
import dayjs from "../../../helpers/days";
import { sentryCaptureException } from "../../../helpers/Sentry";

export default {
  props: ["reservation"],
  data() {
    return {
      reservationStatus: [],
      status: [],
    };
  },
  watch: {
    async reservation() {
      await this.props();
    },
  },
  async mounted() {
    await this.props();
  },
  methods: {
    async props() {
      this.reservationStatus = await reservationApi.getReservationStatusLog(
        this.reservation.id
      );
      if (
        !this.reservationStatus.find(
          (element) => element.reservation_status.id === "OPEN"
        )
      ) {
        this.reservationStatus =
          await reservationApi.createReservationStatusLog(
            this.reservation.id,
            "OPEN"
          );
      }
      const status = await reservationApi.getReservationStatus();
      this.status = status.filter((status) =>
        ["OPEN", "CONFIRMED", "COMPLETED", "AUTHORIZED", "SIGNED"].includes(
          status.id
        )
      );
    },
    getDateFormat(date) {
      return date ? dayjs(date).format("DD/MM/YYYY") : "-";
    },
    reservationStatusDate(id) {
      return this.reservationStatus.find(
        (element) => element.reservation_status.id === id
      )
        ? this.getDateFormat(
            this.reservationStatus.find(
              (element) => element.reservation_status.id === id
            ).created_at
          )
        : "";
    },
    async changeReservationStatus(status) {
      try {
        await reservationApi.createReservationStatusLog(
          this.reservation.id,
          status
        );
        await reservationApi.updateReservationStatusById(
          this.reservation.id,
          status
        );
        this.reservationStatus = await reservationApi.getReservationStatusLog(
          this.reservation.id
        );
      } catch (e) {
        this.$vs.notify({
          title: `Error`,
          text: `Ha ocurrido un error al cambiar estado de la reserva.`,
          color: "danger",
        });
        sentryCaptureException(e, "create-reservation-status");
      }
    },
  },
};
</script>
<style>
.v-application--wrap {
  min-height: auto;
}
</style>
