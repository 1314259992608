<template>
  <div>
    <vx-card>
      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <div
              class="vs-component vs-con-input-label vs-input w-full px-2 vs-input-primary"
            >
              <label for="" class="vs-input--label">
                Nacionalidad
                <span class="text-red-500"> *</span>
              </label>
              <div class="vs-con-input">
                <select
                  v-model="countrySelected"
                  class="vs-inputx vs-input--input normal hasValue w-100 bg-white"
                >
                  <option
                    v-for="option in countries"
                    :key="option.id"
                    :value="option.id"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </div>
              <span></span>
            </div>
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="" class="ml-1 vs-input--label">
              {{ $tc("message.taxNumberId", 1, this.countryCode) }}
              <span class="text-red-500"> *</span>
            </label>
            <vs-input class="w-full px-2" v-model.trim="taxId" name="taxId" />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="" class="ml-1 vs-input--label">
              Nombre de la empresa
              <span class="text-red-500"> *</span>
            </label>
            <vs-input class="w-full px-2" v-model.trim="name" name="name" />
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <vs-input
              class="w-full px-2"
              label="No. Patente Sociedad"
              v-model="societyNumber"
              name="societyNumber"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="" class="ml-1 vs-input--label">
              No. Patente Comercio
            </label>
            <vs-input
              class="w-full px-2"
              v-model.trim="patentNumber"
              name="patentNumber"
            />
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="" class="ml-1 vs-input--label">
              Dirección Fiscal
              <span class="text-red-500"> *</span>
            </label>
            <vs-input
              class="w-full px-2"
              v-model.trim="address"
              name="address"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="" class="ml-1 vs-input--label">
              Teléfono
              <span class="text-red-500"> *</span>
            </label>
            <vs-input class="w-full px-2" v-model.trim="phone" name="phone" />
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <vs-input
              class="w-full px-2"
              label="Correo electrónico"
              v-model.trim="email"
              name="email"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row v-if="editable">
        <vs-col class="sm:w-full lg:w-full p-1">
          <vs-button
            color="success"
            @click="updateCustomer()"
            :disabled="!fieldsReady"
            icon-pack="feather"
            icon="icon-save"
            type="filled"
            ref="loadableButton"
            id="button-with-loading"
            class="vs-con-loading__container w-full mt-2"
          >
            Guardar cambios</vs-button
          >
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import { customerApi } from "../../customer/services";
import countryApi from "../../../services/country";
import { sentryCaptureException } from "../../../helpers/Sentry";
import slugify from "../../../helpers/slugify";
import dayjs from "../../../helpers/days";

export default {
  props: [
    "customerBusiness",
    "business",
    "customerIndividual",
    "individual",
    "reservation",
    "updateFn",
    "countryCode",
    "editable",
  ],
  data() {
    return {
      name: "",
      societyNumber: "",
      patentNumber: "",
      email: "",
      phone: "",
      taxId: "",
      address: "",
      countries: [],
      countrySelected: null,
    };
  },
  async mounted() {
    this.countries = await countryApi.list();
    this.serializeCustomer();
  },
  watch: {
    reservation() {
      this.serializeCustomer();
    },
    taxId(value) {
      const re = /[^A-Z0-9]/gi;
      this.$set(this, "taxId", value.replace(re, "").toUpperCase());
    },
  },
  computed: {
    fieldsReady() {
      if (
        !this.taxId ||
        !this.phone ||
        !this.address ||
        !this.email ||
        !this.name ||
        !this.societyNumber ||
        !this.patentNumber
      ) {
        return false;
      } else return true;
    },
    logoDir() {
      const projectName = slugify(this.reservation.quote.project.name);
      const developerName = slugify(
        this.reservation.quote.project.property_developer.name
      );

      return `${developerName}/projects/${projectName}/gallery`;
    },
  },
  methods: {
    serializeCustomer() {
      this.email = this.customerBusiness.email;
      this.phone = this.customerBusiness.phone;
      this.taxId = this.customerBusiness.tax_identification_number;
      this.address = this.customerBusiness.address;
      this.countrySelected = this.customerBusiness.country_id;
      this.name = this.business.name;
      this.societyNumber = this.business.society_pattent_number;
      this.patentNumber = this.business.business_pattent_number;
    },
    async updateCustomer() {
      try {
        await customerApi.updCustomer({
          business: {
            id: this.customerBusiness.id,
            taxId: this.customerIndividual.tax_identification_number,
            phone: this.customerIndividual.phone,
            email: this.customerIndividual.email,
            address: this.customerIndividual.address,
            addressExtra: this.customerIndividual.extra_address,
            countryId: this.customerIndividual.country_id,
            documentTypeId: this.individual.document_type_id,
            personalIdentityNumber: this.individual.personal_identity_number,
            firstName: this.individual.first_name,
            middleName: this.individual.middle_name,
            extraName: this.individual.extra_name,
            firstSurname: this.individual.first_surname,
            secondSurname: this.individual.second_surname,
            marriedSurname: this.individual.marriedSurname,
            genderId: this.individual.gender_id,
            maritalStatusId: this.individual.marital_status_id,
            workplaceName: this.individual.workplace_name,
            jobTitle: this.individual.job_title,
            profession: this.individual.profession,
            workAddress: this.individual.work_address,
            monthlyIncome: this.individual.monthly_income,
            monthlyExpense: this.individual.monthly_expense,
            businessName: this.business.name,
            businessPhone: this.customerBusiness.phone,
            businessEmail: this.customerBusiness.email,
            businessTaxId: this.customerBusiness.tax_identification_number,
            businessCountryId: this.customerBusiness.country_id,
            businessAddress: this.customerBusiness.address,
            societyPattentNumber: this.business.society_pattent_number,
            businessPattentNumber: this.business.business_pattent_number,
            legalRepresentativeRegistry:
              this.business.legal_representative_registry,
            legalRepresentativePage: this.business.legal_representative_page,
            legalRepresentativeBook: this.business.legal_representative_book,
            legalRepresentativeNominationType:
              this.business.legal_representative_nomination_type,
            legalRepresentativeNominationDate: this.business
              .legal_representative_nomination_date
              ? dayjs(
                  this.business.legal_representative_nomination_date
                ).format("YYYY-MM-DDTHH:mm:ssZ")
              : null,
            legalRepresentativeNominationDuration:
              this.business.legal_representative_nomination_duration,
          },
        });

        this.updateFn(true);

        //Update parent component and reservation request
        this.$root.$emit("update-reservation", {
          reservationId: this.reservation.id,
        });
      } catch (e) {
        sentryCaptureException(e, "customer");
        this.$vs.notify({
          title: `Error`,
          text: `No ha sido posible actualizar cliente en este momento.`,
          color: "danger",
        });
      }
    },
  },
};
</script>