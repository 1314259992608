<template>
  <div>
    <div
      class="fixed z-10 inset-0 overflow-y-auto mt-24 md:mt-4"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-400 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
        >
          <div class="block absolute top-0 right-0 pt-4 pr-4">
            <button
              @click="closeModalPaymentDetail"
              type="button"
              class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <span class="sr-only">Close</span>
              <!-- Heroicon name: outline/x -->
              <svg
                class="h-10 w-10 hover:text-danger"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div class="sm:flex sm:items-start">
            <div
              class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10"
            >
              <!-- Heroicon name: outline/exclamation -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-green-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <div class="text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                class="text-2xl leading-6 font-medium text-gray-900 mt-2"
                id="modal-title"
              >
                Detalle de pago
              </h3>
            </div>
          </div>
          <div>
            <div class="flow-root mt-6">
              <ul role="list" class="">
                <li>
                  <div class="relative pb-8">
                    <div class="relative flex items-start space-x-3">
                      <div class="min-w-0 flex-1">
                        <div class="mt-2 text-base text-gray-600">
                          <p>Estado: {{ payments.payment_status.name }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  v-for="p in payments.reservation_downpayment_payments"
                  :key="p.id"
                >
                  <div class="relative pb-8">
                    <div class="relative flex items-start space-x-3">
                      <div class="min-w-0 flex-1">
                        <div>
                          <p class="mt-0.5 text-sm text-gray-500">
                            Fecha de ingreso:
                            {{ parseDate(p.reservation_payment.created_at) }}
                          </p>
                        </div>
                        <div class="mt-2 text-base text-gray-600">
                          <p>
                            Monto: {{ p.reservation_payment.amount.toFixed(2) }}
                          </p>
                        </div>
                        <div class="mt-2 text-base text-gray-600">
                          <p>
                            Método de pago:
                            {{
                              p.reservation_payment.payment_method
                                ? p.reservation_payment.payment_method.name
                                : ""
                            }}
                          </p>
                        </div>
                        <div class="mt-2 text-base text-gray-600">
                          <p>
                            Número de referencia:
                            {{
                              p.reservation_payment.document_reference
                                ? p.reservation_payment.document_reference
                                : ""
                            }}
                          </p>
                        </div>
                        <div class="mt-2 text-base text-gray-600">
                          <p
                            v-html="
                              `Descripción: ${p.reservation_payment.description}`
                            "
                          ></p>
                        </div>
                        <div
                          class="mt-2 text-base text-gray-600 flex text-blue-flattlo"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6 mr-1"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            />
                          </svg>
                          <a
                            :href="p.reservation_payment.document_url"
                            target="_blank"
                          >
                            Ver comprobante ingresado
                          </a>
                        </div>
                        <div
                          class="mt-2 text-base text-gray-600 flex text-blue-flattlo"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6 mr-1"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            />
                          </svg>
                          <a
                            role="button"
                            @click="
                              openPaymentVoucher(
                                payments.reservation,
                                p.reservation_payment,
                              )
                            "
                          >
                            Ver recibo generado
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { reservationApi } from "../services";
import dayjs from "../../../helpers/days";

export default {
  data() {
    return {
      payments: [],
    };
  },
  computed: {
    ...mapState("reservation", ["reservationId", "payment"]),
  },
  watch: {
    async payment() {
      await this.getPayments();
    },
  },
  async mounted() {
    await this.getPayments();
  },
  methods: {
    closeModalPaymentDetail() {
      this.$store.commit("reservation/setModalPaymentDetail", false);
    },
    async getPayments() {
      this.payments = await reservationApi.getDownpaymentById(this.payment.id);
    },
    parseDate(str) {
      return dayjs(str).format("DD/MM/YYYY");
    },
    openPaymentVoucher(reservation, payment) {
      const paymentVoucherUrl = process.env.VUE_APP_HANDLER_URL;
      const url = `${paymentVoucherUrl}/reservation/${reservation.id}/payment/${payment.id}/pdf`;
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped></style>