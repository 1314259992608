var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:px-8 px-2"},[(_vm.adjustingQuotes)?_c('header',{staticClass:"sticky top-24 z-50 rounded-md p-2 bg-green-100"},[_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"text-xs sm:text-sm text-green-700"},[_c('svg',{staticClass:"hidden sm:inline h-5 w-5 text-green-400",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z","clip-rule":"evenodd"}})]),_vm._v("\n        Opciones\n      ")]),_c('div',[(this.remainingToReachDownpayment != 0)?_c('span',{staticClass:"text-xs sm:text-sm text-yellow-700"},[_vm._v("\n          "+_vm._s(this.remainingToReachDownpayment > 0
              ? `Hacen falta ${_vm.getFormatCurrency(
                  this.remainingToReachDownpayment
                )}`
              : `Sobran ${_vm.getFormatCurrency(
                  this.remainingToReachDownpayment
                )}`)+"\n          en las cuotas\n        ")]):_vm._e(),_c('button',{staticClass:"whitespace-nowrap bg-green-500 p-2 rounded-full text-white shadow text-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-1",on:{"click":_vm.selectToggle}},[_vm._v("\n          "+_vm._s(this.selectingBtnText)+"\n        ")]),_c('button',{staticClass:"whitespace-nowrap bg-green-500 p-2 rounded-full text-white shadow text-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-1",on:{"click":_vm.adjustNotSelectedQuotes}},[_vm._v("\n          Recalcular\n        ")]),_vm._v("\n        |\n        "),(this.remainingToReachDownpayment == 0)?_c('button',{staticClass:"whitespace-nowrap bg-green-500 p-2 rounded-full text-white shadow text-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-1",on:{"click":_vm.saveQuotes}},[_vm._v("\n          Guardar\n        ")]):_vm._e(),_c('button',{staticClass:"whitespace-nowrap bg-green-500 p-2 rounded-full text-white shadow text-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",on:{"click":_vm.adjustQuotes}},[_vm._v("\n          Cerrar\n        ")])])])]):_vm._e(),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"},[_c('div',{staticClass:"py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"},[_c('div',{staticClass:"flex flex-row p-2"},[_c('div',{staticClass:"flex flex-col"},[_c('vs-button',{staticClass:"m-1 mb-4",attrs:{"color":"success","type":"filled","icon-pack":"feather","icon":"icon-plus"},on:{"click":_vm.openPayments}},[_vm._v("Ver Pagos")])],1),_c('div',{staticClass:"flex flex-col"},[(
                !_vm.adjustingQuotes &&
                (_vm.user.role === 'ADMIN' || _vm.user.role === 'SUPERVISOR')
              )?_c('div',{staticClass:"flex flex"},[_c('vs-button',{staticClass:"m-1 mb-4",attrs:{"color":"warning","type":"filled","icon-pack":"feather","icon":"icon-plus"},on:{"click":_vm.adjustQuotes}},[_vm._v("Ajustar Cuotas")])],1):_vm._e()])]),_c('div',{staticClass:"shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"},[_c('table',{staticClass:"min-w-full divide-y divide-gray-200"},[_vm._m(0),_c('tbody',_vm._l((_vm.downpayments),function(quote,i){return _c('tr',{key:quote.id,staticClass:"bg-white text-base bg-gray-400"},[_c('td',{staticClass:"px-6 py-4 whitespace-nowrap text-center font-medium text-gray-700"},[_vm._v("\n                  "+_vm._s(i + 1)+"\n                ")]),_c('td',{staticClass:"px-6 py-4 whitespace-nowrap text-center text-gray-700 hidden md:table-cell"},[(quote.payment_type)?_c('p',[_vm._v("\n                    "+_vm._s(_vm.getPaymentType(quote.payment_type))+"\n                  ")]):_vm._e()]),_c('td',{staticClass:"p-2 whitespace-nowrap text-center text-gray-700"},[(
                      _vm.adjustingQuotes &&
                      _vm.totalPayments(quote.reservation_downpayment_payments) ==
                        0
                    )?_c('p',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.quotesCheckboxes[i]),expression:"quotesCheckboxes[i]"}],staticClass:"mr-1 shadow-inner rounded-md",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.quotesCheckboxes[i])?_vm._i(_vm.quotesCheckboxes[i],null)>-1:(_vm.quotesCheckboxes[i])},on:{"click":function($event){return _vm.updListOfSelectedQuotes(i)},"change":function($event){var $$a=_vm.quotesCheckboxes[i],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.quotesCheckboxes, i, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.quotesCheckboxes, i, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.quotesCheckboxes, i, $$c)}}}}),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.customQuotes[i].amount),expression:"customQuotes[i].amount",modifiers:{"number":true}}],staticClass:"w-1/2 px-2 bg-gray-100 border border-gray-200 text-center rounded",class:_vm.selectedQuotes.includes(i)
                          ? 'bg-blue-200 border-blue-300 border-2 hover:bg-blue'
                          : 'bg-gray-100 hover:bg-white border-none',attrs:{"type":"number"},domProps:{"value":(_vm.customQuotes[i].amount)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.customQuotes[i], "amount", _vm._n($event.target.value))},function($event){return _vm.chgSelectedQuotes(i)}],"blur":function($event){return _vm.$forceUpdate()}}})]):_vm._e(),(
                      !_vm.adjustingQuotes ||
                      _vm.totalPayments(quote.reservation_downpayment_payments) >
                        0
                    )?_c('p',[_vm._v("\n                    "+_vm._s(_vm.getFormatCurrency(_vm.customQuotes[i].amount))+"\n                  ")]):_vm._e()]),_c('td',{staticClass:"px-6 py-4 whitespace-nowrap text-center text-gray-700 hidden md:table-cell"},[_c('p',[_vm._v("\n                    "+_vm._s(_vm.getFormatCurrency(
                        _vm.totalPayments(quote.reservation_downpayment_payments)
                      ))+"\n                  ")])]),_c('td',{staticClass:"p-2 whitespace-nowrap text-center text-gray-700 hidden md:table-cell"},[(
                      _vm.adjustingQuotes &&
                      _vm.totalPayments(quote.reservation_downpayment_payments) ==
                        0
                    )?_c('input',{staticClass:"w-1/2 px-2 bg-gray-100 border border-gray-200 text-center rounded",domProps:{"value":_vm.getFormatDate(_vm.customQuotes[i].dueDate)},on:{"input":function($event){return _vm.updateDate($event.target.value, i)}}}):_vm._e(),(
                      quote.due_date &&
                      !(
                        _vm.adjustingQuotes &&
                        _vm.totalPayments(
                          quote.reservation_downpayment_payments
                        ) == 0
                      )
                    )?_c('p',[_vm._v("\n                    "+_vm._s(_vm.getFormatDate(_vm.customQuotes[i].dueDate))+"\n                  ")]):_vm._e()]),_c('td',{class:`whitespace-nowrap bg-${_vm.statusColor(
                    quote.payment_status
                  )} text-white text-center text-gray-700 font-semibold`},[(quote.payment_status)?_c('p',[_vm._v("\n                    "+_vm._s(quote.payment_status.name)+"\n                  ")]):_vm._e()]),_c('td',{staticClass:"px-6 py-4 font-medium text-center"},[(quote.payment_status.id === 'PAID')?_c('a',{staticClass:"flex justify-center",attrs:{"role":"button"},on:{"click":function($event){return _vm.openPaymentDetail(quote)}}},[_c('svg',{staticClass:"h-6 w-6 text-gray-500 text-center hover:text-blue-flattlo",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15 12a3 3 0 11-6 0 3 3 0 016 0z"}}),_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"}})])]):(_vm.reservation.status !== 'LOST')?_c('a',{staticClass:"flex justify-center",attrs:{"role":"button"},on:{"click":function($event){return _vm.openPaymentModal(quote)}}},[_c('svg',{staticClass:"h-6 w-6 text-gray-500 text-center hover:text-blue-flattlo",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"}})])]):_c('div',[_c('vs-chip',[_vm._v("\n                      "+_vm._s(_vm.reservation.reservation_status.name)+"\n                    ")])],1)])])}),0)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-gray-200"},[_c('tr',[_c('th',{staticClass:"px-6 py-3 text-center text-gray-500 uppercase tracking-wider",attrs:{"scope":"col"}},[_vm._v("\n                  No.\n                ")]),_c('th',{staticClass:"px-6 py-3 text-center text-gray-500 uppercase tracking-wider hidden md:table-cell",attrs:{"scope":"col"}},[_vm._v("\n                  Tipo de pago\n                ")]),_c('th',{staticClass:"px-6 py-3 text-center text-gray-500 uppercase tracking-wider",attrs:{"scope":"col"}},[_vm._v("\n                  Monto a pagar\n                ")]),_c('th',{staticClass:"px-6 py-3 text-center text-gray-500 uppercase tracking-wider hidden md:table-cell",attrs:{"scope":"col"}},[_vm._v("\n                  Monto pagado\n                ")]),_c('th',{staticClass:"px-6 py-3 text-center text-gray-500 uppercase tracking-wider hidden md:table-cell",attrs:{"scope":"col"}},[_vm._v("\n                  Fecha de corte\n                ")]),_c('th',{staticClass:"px-6 py-3 text-center text-gray-500 uppercase tracking-wider",attrs:{"scope":"col"}},[_vm._v("\n                  Estado\n                ")]),_c('th',{staticClass:"px-6 py-3 text-center text-gray-500 uppercase tracking-wider",attrs:{"scope":"col"}},[_vm._v("\n                  Ver\n                ")])])])
}]

export { render, staticRenderFns }