<template>
  <div>
    <div class="flex">
      <div class="flex w-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-8 w-8 ml-12 mt-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
          />
        </svg>
        <p class="text-2xl font-medium ml-2 mt-7">
          Detalle unidad: {{ mainProperty.unit_number }}
          <span v-if="mainProperty.tower"
            >, Torre {{ mainProperty.tower }}</span
          >
        </p>
      </div>

      <div class="w-full float-right">
        <p class="text-2xl font-medium ml-2 mt-7 float-right">
          Vendedor: {{ seller.first_name }} {{ seller.last_name }}
        </p>
      </div>
    </div>
    <div class="relative mt-4">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div class="w-full border-t border-gray-300"></div>
      </div>
    </div>

    <div class="w-full">
      <StepperComponent :reservation="reservation" />
    </div>
    <div class="md:grid-col-3 md:flex grid gap-2 md:p-8- mt-5">
      <div class="w-full" v-if="customers.length > 0">
        <div v-for="item in customers" :key="item.customer.id">
          <ClientComponent
            v-if="item.customer.customer_individual"
            :reservation="reservation"
            :customer="item.customer"
          />
          <BusinessComponent
            v-else
            :reservation="reservation"
            :customer="item.customer"
          />
        </div>
      </div>

      <div class="w-full">
        <TotalPaymentsComponent
          :downpayments="downpayments"
          :reservation="reservation"
        />
      </div>

      <div class="w-full">
        <PropertyDetailComponent
          :reservation="reservation"
          :property="mainProperty"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StepperComponent from "./StepperComponent.vue";
import ClientComponent from "./ClientComponent.vue";
import BusinessComponent from "./BusinessComponent.vue";
import TotalPaymentsComponent from "./TotalPaymentsComponent.vue";
import PropertyDetailComponent from "./PropertyDetailComponent.vue";

export default {
  props: ["reservation", "downpayments", "seller"],
  components: {
    ClientComponent,
    TotalPaymentsComponent,
    PropertyDetailComponent,
    StepperComponent,
    BusinessComponent,
  },
  data() {
    return {
      mainProperty: null,
      customers: [],
    };
  },
  watch: {
    reservation() {
      this.props();
    },
  },
  mounted() {
    this.props();
  },
  methods: {
    props() {
      this.customers = this.reservation.customer_reservations || [];
      if (this.reservation.quote.quote_details.length) {
        const filteredProp = this.reservation.quote.quote_details.filter(
          (i) =>
            i.property.property_type_id !== "WAREHOUSE" &&
            i.property.property_type_id !== "PARKING",
        );

        const mainProp = filteredProp.length ? filteredProp[0].property : null;
        if (mainProp.property_attribute_values.length) {
          let tower = mainProp.property_attribute_values.filter(
            (p) => p.property_attribute === "TOWER_NUMBER",
          );
          try {
            mainProp.tower = (tower && tower[0].value) || null;
          } catch (error) {
            mainProp.tower = null;
          }
        }
        this.mainProperty = mainProp;
      } else this.mainProperty = null;
    },
  },
};
</script>
<style>
[dir] .mt-6 {
  margin-top: 0rem !important;
}
</style>