import gql from "graphql-tag";
import graphqlClient from "../../../graphqlClient";

const getById = async customerId => {
  const response = await graphqlClient.query({
    query: gql`
      query get($id: uuid!) {
        customer_by_pk(id: $id) {
          id
          tax_identification_number
          full_name
          phone
          email
          address
          customer_individual {
            id
            first_name
            middle_name
            first_surname
            second_surname
            married_surname
            personal_identity_number
          }
          customer_documents {
            id
            document_url
            document_type_id
          }
        }
      }
    `,
    variables: {
      id: customerId
    }
  });
  return response.data.customer_by_pk;
};

const getIndividualByTaxId = async taxId => {
  const response = await graphqlClient.query({
    query: gql`
      query getCustomerIndividal($id: String!) {
        customer(where: { tax_identification_number: { _eq: $id } }) {
          id
          tax_identification_number
          phone
          email
          country_id
          address
          property_developer_id
          customer_documents {
            id
            document_url
            document_type_id
          }
          customer_individual {
            personal_identity_number
            first_name
            middle_name
            first_surname
            second_surname
            married_surname
            document_type {
              id
              name
            }
          }
        }
      }
    `,
    variables: {
      id: taxId
    }
  });

  return response.data.customer.length ? response.data.customer[0] : null;
};

async function addIndividual(customer) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation createIndividual($input: customerIndividualInput!) {
        createCustomerIndividual(customer: $input) {
          customerId
        }
      }
    `,
    variables: {
      input: customer
    }
  });

  return response.data.createCustomerIndividual;
}

async function updIndividual(customer) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation updCustomerIndividual($input: updCustomerIndividualInput!) {
        updateCustomerIndividual(customer: $input) {
          customerId
        }
      }
    `,
    variables: {
      input: customer
    }
  });

  return response.data.updateCustomerIndividual;
}

async function addCustomerDocument(customer) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation addCustomerDocument($input: customer_document_insert_input!) {
        insert_customer_document_one(object: $input) {
          id
          document_url
          document_type_id
        }
      }
    `,
    variables: {
      input: customer
    }
  });

  return response.data.insert_customer_document_one;
}

const getDocumentPersonal = async () => {
  const types = ["PASSPORT", "PERSONAL_DOCUMENT_ID"];

  const response = await graphqlClient.query({
    query: gql`
      query getPersonalDocuments($types: [String!]) {
        document_type(where: { id: { _in: $types } }) {
          id
          name
        }
      }
    `,
    variables: {
      types
    }
  });

  return response.data.document_type;
};

async function deleteCustomerDocument(customerId, documentTypeId) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation deleteCustomerDocument(
        $customerId: uuid!
        $documentTypeId: document_type_enum!
      ) {
        delete_customer_document(
          where: {
            document_type_id: { _eq: $documentTypeId }
            customer_id: { _eq: $customerId }
          }
        ) {
          affected_rows
          __typename
        }
      }
    `,
    variables: {
      documentTypeId,
      customerId
    }
  });

  return response.data.delete_customer_document;
}


async function updCustomer(customer) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation updateCustomer ($customer: UpdCustomerInput!) {
        updateCustomer(customer: $customer) {
          customerId
        }
      }
    `,
    variables: {
      customer
    }
  });

  return response.data.updateCustomer;
}

export default {
  getById,
  getIndividualByTaxId,
  addIndividual,
  updIndividual,
  addCustomerDocument,
  getDocumentPersonal,
  deleteCustomerDocument,
  updCustomer
};
