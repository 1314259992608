<template>
  <div class="md:px-8 px-2">
    <header
      class="sticky top-24 z-50 rounded-md p-2 bg-green-100"
      v-if="adjustingQuotes"
    >
      <div class="flex justify-between">
        <div class="text-xs sm:text-sm text-green-700">
          <svg
            class="hidden sm:inline h-5 w-5 text-green-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clip-rule="evenodd"
            />
          </svg>
          Opciones
        </div>
        <div>
          <span
            v-if="this.remainingToReachDownpayment != 0"
            class="text-xs sm:text-sm text-yellow-700"
          >
            {{
              this.remainingToReachDownpayment > 0
                ? `Hacen falta ${getFormatCurrency(
                    this.remainingToReachDownpayment
                  )}`
                : `Sobran ${getFormatCurrency(
                    this.remainingToReachDownpayment
                  )}`
            }}
            en las cuotas
          </span>
          <button
            class="whitespace-nowrap bg-green-500 p-2 rounded-full text-white shadow text-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-1"
            @click="selectToggle"
          >
            {{ this.selectingBtnText }}
          </button>
          <button
            class="whitespace-nowrap bg-green-500 p-2 rounded-full text-white shadow text-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-1"
            @click="adjustNotSelectedQuotes"
          >
            Recalcular
          </button>
          |
          <button
            v-if="this.remainingToReachDownpayment == 0"
            class="whitespace-nowrap bg-green-500 p-2 rounded-full text-white shadow text-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-1"
            @click="saveQuotes"
          >
            Guardar
          </button>
          <button
            class="whitespace-nowrap bg-green-500 p-2 rounded-full text-white shadow text-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click="adjustQuotes"
          >
            Cerrar
          </button>
        </div>
      </div>
    </header>

    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="flex flex-row p-2">
            <div class="flex flex-col">
              <vs-button
                class="m-1 mb-4"
                color="success"
                type="filled"
                icon-pack="feather"
                icon="icon-plus"
                @click="openPayments"
                >Ver Pagos</vs-button
              >
            </div>
            <div class="flex flex-col">
              <div
                class="flex flex"
                v-if="
                  !adjustingQuotes &&
                  (user.role === 'ADMIN' || user.role === 'SUPERVISOR')
                "
              >
                <vs-button
                  class="m-1 mb-4"
                  color="warning"
                  type="filled"
                  icon-pack="feather"
                  icon="icon-plus"
                  @click="adjustQuotes"
                  >Ajustar Cuotas</vs-button
                >
              </div>
            </div>
          </div>

          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-200">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-center text-gray-500 uppercase tracking-wider"
                  >
                    No.
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-center text-gray-500 uppercase tracking-wider hidden md:table-cell"
                  >
                    Tipo de pago
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-center text-gray-500 uppercase tracking-wider"
                  >
                    Monto a pagar
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-center text-gray-500 uppercase tracking-wider hidden md:table-cell"
                  >
                    Monto pagado
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-center text-gray-500 uppercase tracking-wider hidden md:table-cell"
                  >
                    Fecha de corte
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-center text-gray-500 uppercase tracking-wider"
                  >
                    Estado
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-center text-gray-500 uppercase tracking-wider"
                  >
                    Ver
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- Odd row -->
                <tr
                  v-for="(quote, i) in downpayments"
                  :key="quote.id"
                  class="bg-white text-base bg-gray-400"
                >
                  <td
                    class="px-6 py-4 whitespace-nowrap text-center font-medium text-gray-700"
                  >
                    {{ i + 1 }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-center text-gray-700 hidden md:table-cell"
                  >
                    <p v-if="quote.payment_type">
                      {{ getPaymentType(quote.payment_type) }}
                    </p>
                  </td>
                  <td class="p-2 whitespace-nowrap text-center text-gray-700">
                    <p
                      v-if="
                        adjustingQuotes &&
                        totalPayments(quote.reservation_downpayment_payments) ==
                          0
                      "
                    >
                      <input
                        type="checkbox"
                        class="mr-1 shadow-inner rounded-md"
                        v-model="quotesCheckboxes[i]"
                        @click="updListOfSelectedQuotes(i)"
                      />
                      <input
                        class="w-1/2 px-2 bg-gray-100 border border-gray-200 text-center rounded"
                        :class="
                          selectedQuotes.includes(i)
                            ? 'bg-blue-200 border-blue-300 border-2 hover:bg-blue'
                            : 'bg-gray-100 hover:bg-white border-none'
                        "
                        type="number"
                        v-model.number="customQuotes[i].amount"
                        @input="chgSelectedQuotes(i)"
                      />
                    </p>
                    <p
                      v-if="
                        !adjustingQuotes ||
                        totalPayments(quote.reservation_downpayment_payments) >
                          0
                      "
                    >
                      {{ getFormatCurrency(customQuotes[i].amount) }}
                    </p>
                  </td>

                  <td
                    class="px-6 py-4 whitespace-nowrap text-center text-gray-700 hidden md:table-cell"
                  >
                    <p>
                      {{
                        getFormatCurrency(
                          totalPayments(quote.reservation_downpayment_payments)
                        )
                      }}
                    </p>
                  </td>
                  <td
                    class="p-2 whitespace-nowrap text-center text-gray-700 hidden md:table-cell"
                  >
                    <input
                      class="w-1/2 px-2 bg-gray-100 border border-gray-200 text-center rounded"
                      :value="getFormatDate(customQuotes[i].dueDate)"
                      @input="updateDate($event.target.value, i)"
                      v-if="
                        adjustingQuotes &&
                        totalPayments(quote.reservation_downpayment_payments) ==
                          0
                      "
                    />
                    <p
                      v-if="
                        quote.due_date &&
                        !(
                          adjustingQuotes &&
                          totalPayments(
                            quote.reservation_downpayment_payments
                          ) == 0
                        )
                      "
                    >
                      {{ getFormatDate(customQuotes[i].dueDate) }}
                    </p>
                  </td>
                  <td
                    :class="`whitespace-nowrap bg-${statusColor(
                      quote.payment_status
                    )} text-white text-center text-gray-700 font-semibold`"
                  >
                    <p v-if="quote.payment_status">
                      {{ quote.payment_status.name }}
                    </p>
                  </td>

                  <td class="px-6 py-4 font-medium text-center">
                    <a
                      @click="openPaymentDetail(quote)"
                      role="button"
                      class="flex justify-center"
                      v-if="quote.payment_status.id === 'PAID'"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 text-gray-500 text-center hover:text-blue-flattlo"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                        />
                      </svg>
                    </a>

                    <a
                      role="button"
                      class="flex justify-center"
                      v-else-if="reservation.status !== 'LOST'"
                      @click="openPaymentModal(quote)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 text-gray-500 text-center hover:text-blue-flattlo"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                        />
                      </svg>
                    </a>

                    <div v-else>
                      <vs-chip>
                        {{ reservation.reservation_status.name }}
                      </vs-chip>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "../../../helpers/days";
import currencies from "../../../helpers/currencies";
import { reservationApi } from "../services";
import { sentryCaptureException } from "../../../helpers/Sentry";

export default {
  props: ["downpayments", "reservation"],
  data() {
    return {
      adjustingQuotes: false,
      customQuotes: [],
      quotesCheckboxes: [],
      selectedQuotes: [],
      selectingMode: false,
      quoteCount: 0,
    };
  },
  async mounted() {
    this.generateInternalQuotes();
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("reservation", ["reservationId"]),
    selectingBtnText() {
      return this.selectedQuotes.length == this.customQuotes.length &&
        this.selectedQuotes.length > 1
        ? "Eliminar selección"
        : "Seleccionar todo";
    },
    totalDownpayment() {
      return this.downpayments.reduce((accum, q) => accum + q.amount, 0);
    },
    remainingDownpayment() {
      return this.downpayments
        .filter((q) => q.reservation_downpayment_payments.length === 0) // filtrar por que no tenga pagos
        .reduce(
          (accum, q) => accum + parseFloat(q.amount ? q.amount.toFixed(2) : 0),
          0
        );
    },
    countPaidQuotes() {
      return this.downpayments.filter(
        (q) => q.reservation_downpayment_payments.length > 0
      ).length;
    },
    remainingToReachDownpayment() {
      const quotes = this.customQuotes.slice(this.countPaidQuotes);
      return parseFloat(
        (
          this.remainingDownpayment -
          quotes.reduce(
            (accum, q) => accum + parseFloat(parseFloat(q.amount).toFixed(2)),
            0
          )
        ).toFixed(2)
      );
    },
  },
  methods: {
    statusColor({ id }) {
      if (id == "ONTIME") {
        return "green-400";
      }
      if (id == "PAID") {
        return "green-600";
      }
      if (id == "PARTIAL") {
        return "yellow-400";
      }
      if (id == "DELAYED") {
        return "red-400";
      } else {
        return "gray-300";
      }
    },
    async openPaymentModal(downpayment) {
      this.$store.commit(
        "reservation/setReservationId",
        downpayment.reservation.id
      );
      this.$store.commit("reservation/setPayment", downpayment);
      const payments = await reservationApi.getDownpaymentById(downpayment.id);
      const totalPaid = this.totalPayments(
        payments.reservation_downpayment_payments
      );

      if (totalPaid > 0) {
        this.$store.commit(
          "reservation/setPaymentAmount",
          downpayment.amount - totalPaid
        );
      } else
        this.$store.commit("reservation/setPaymentAmount", downpayment.amount);

      this.$store.commit("reservation/setModalPayment", true);
    },
    openPaymentDetail(downpayment) {
      this.$store.commit(
        "reservation/setReservationId",
        downpayment.reservation.id
      );
      this.$store.commit("reservation/setPayment", downpayment);
      this.$store.commit("reservation/setPaymentAmount", downpayment.amount);
      this.$store.commit("reservation/setModalPaymentDetail", true);
    },
    totalPayments(payment) {
      if (!payment.length) return 0;

      const paid = payment.reduce((prev, last) => {
        return prev + (!last.reservation_payment.canceled ? last.amount : 0);
      }, 0);
      return paid;
    },
    getFormatCurrency(value) {
      const currencySymbol = this.$store.getters["project/currencySymbol"];

      return currencies.currencyFormatter({ value, currency: currencySymbol });
    },
    getFormatDate(value) {
      return value ? dayjs(value).format("DD/MM/YYYY") : "";
    },
    updateDate(date, index) {
      if (!dayjs(date, "DD/MM/YYYY").isValid()) return;
      const originalDate = this.customQuotes[index].dueDate;
      const year = dayjs(originalDate).year();
      const month = dayjs(originalDate).month();
      const day = dayjs(originalDate).date();

      const newDate = dayjs(date, "DD/MM/YYYY")
        .set({ year, month, day })
        .toDate();

      this.customQuotes[index].dueDate = newDate;
    },
    generateInternalQuotes() {
      this.customQuotes = this.downpayments.map((q) => {
        return {
          id: q.id,
          amount: q.amount ? q.amount.toFixed(2) : 0,
          dueDate: q.due_date,
          type: q.payment_type.name,
        };
      });
    },
    async saveQuotes() {
      try {
        await reservationApi.updateDownpaymentQuotes(
          this.reservation.id,
          this.customQuotes
        );
        this.adjustQuotes();
        this.$root.$emit("reload-payments", { reserveId: this.reservation.id });
      } catch (e) {
        sentryCaptureException(e, "save-quotes");
      }
    },
    adjustQuotes() {
      this.clearSelection();
      this.adjustingQuotes = !this.adjustingQuotes;
    },
    adjustNotSelectedQuotes() {
      if (this.customQuotes.length > this.selectedQuotes.length) {
        const totalSelected = this.selectedQuotes.reduce(
          (accum, index) => accum + this.customQuotes[index].amount,
          0
        );

        const newQuote = parseFloat(
          (
            (this.remainingDownpayment - totalSelected) /
            (this.customQuotes.length -
              this.countPaidQuotes -
              this.selectedQuotes.length)
          ).toFixed(2)
        );

        const quotes = [...this.customQuotes];

        quotes.forEach((_, i) => {
          if (i >= this.countPaidQuotes && !this.selectedQuotes.includes(i)) {
            quotes[i] = newQuote;
          }
        });

        this.customQuotes = quotes;
        2;
        if (
          this.remainingToReachDownpayment !== 0 &&
          this.remainingToReachDownpayment <= 1 &&
          this.remainingToReachDownpayment >= -1
        ) {
          quotes[quotes.length - 1] = parseFloat(
            (
              quotes[quotes.length - 1] + this.remainingToReachDownpayment
            ).toFixed(2)
          );
          setTimeout(() => {
            this.customQuotes = quotes;
          }, 200);
        }
      }
    },

    updListOfSelectedQuotes(quoteNumber) {
      if (this.selectedQuotes.includes(quoteNumber)) {
        this.selectedQuotes.splice(this.selectedQuotes.indexOf(quoteNumber), 1);
      } else this.selectedQuotes.push(quoteNumber);

      if (this.selectedQuotes.length == 1) this.selectingMode = true;
    },
    selectToggle() {
      if (this.selectedQuotes.length == this.customQuotes.length) {
        this.clearSelection();
      } else {
        this.selectedQuotes = [];
        for (let i = 0; i < this.customQuotes.length; i += 1) {
          this.selectedQuotes[i] = i;
          this.quotesCheckboxes[i] = true;
        }
      }
    },
    clearSelection() {
      this.selectedQuotes = [];
      this.quotesCheckboxes = [];
      this.selectingMode = false;
    },
    chgSelectedQuotes(quoteNumber) {
      if (this.selectedQuotes.includes(quoteNumber)) {
        const newValue = parseFloat(this.customQuotes[quoteNumber].amount);
        const updateCustomQuotes = (quoteIndex) => {
          if (quoteIndex !== quoteNumber) {
            this.customQuotes[quoteIndex].amount = parseFloat(newValue);
          }
        };
        this.selectedQuotes.forEach(updateCustomQuotes);
      }
    },
    openPayments() {
      this.$router.push({
        name: "Pagos",
        params: {
          reservationId: this.reservation.id,
        },
      });
    },
    getPaymentType(paymentType) {
      const countryCode =
        this.reservation.quote.project.city.country.code || "us";

      switch (paymentType.id) {
        case "RESERVE":
          return this.$tc("message.Reservation", 1, countryCode);
        case "FINANCING":
          return this.$tc("message.Financing", 1, countryCode);
        default:
          return paymentType.name;
      }
    },
  },
};
</script>
