import gql from "graphql-tag";
import graphqlClient from "../graphqlClient";

const list = async () => {
  const res = await graphqlClient.query({
    query: gql`
      {
        marital_status {
          id
          name
        }
      }
    `
  });

  return res.data.marital_status;
};

export default {
  list
};
