<template>
  <div>
    <p class="text-semibold">
      Porcentaje Pagado:
    </p>
    <p class="font-semibold mt-1 text-green-500">
      {{ percentPaid() }} %
    </p>
  </div>
</template>

<script>
export default {
  props: ["reservation", "downpayments"],
  methods: {
    percentPaid() {
      let totalPaid = this.downpayments.filter(
        d => d.reservation_downpayment_payments.length > 0
      );

      if (!totalPaid.length) return 0;

      totalPaid = totalPaid
        .map(p => {
          return p.reservation_downpayment_payments.reduce(
            (accum, last) => accum + last.amount,
            0
          );
        })
        .reduce((accum, last) => accum + last);

      const totalDownpayment = this.downpayments.reduce(
        (prev, last) => prev + last.amount,
        0
      );

      const total = (100 * totalPaid) / totalDownpayment;

      return total.toFixed(2);
    }
  }
};
</script>

<style lang="scss" scoped></style>
