<template>
  <div>
    <vx-card>
      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <div
              class="vs-component vs-con-input-label vs-input w-full px-2 vs-input-primary"
            >
              <label for="" class="vs-input--label">
                Nacionalidad
                <span class="text-red-500"> *</span>
              </label>
              <div class="vs-con-input">
                <select
                  v-model="countrySelected"
                  class="vs-inputx vs-input--input normal hasValue w-100 bg-white"
                  disabled
                >
                  <option
                    v-for="option in countries"
                    :key="option.id"
                    :value="option.id"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </div>
              <span></span>
            </div>
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="" class="ml-1 vs-input--label">
              {{ $tc("message.taxNumberId", 1, this.countryCode) }}
              <span class="text-red-500"> *</span>
            </label>
            <vs-input
              class="w-full px-2"
              v-model.trim="taxId"
              name="taxId"
              disabled
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <div
              class="vs-component vs-con-input-label vs-input w-full px-2 vs-input-primary"
            >
              <label for="" class="vs-input--label">
                Tipo de documento
                <span class="text-red-500"> *</span>
              </label>
              <div class="vs-con-input">
                <select
                  v-model="documentTypeSelected"
                  class="vs-inputx vs-input--input normal hasValue w-100 bg-white"
                  disabled
                >
                  <option
                    v-for="option in documentTypes"
                    :key="option.id"
                    :value="option.id"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="" class="ml-1 vs-input--label">
              No. de documento
              <span class="text-red-500"> *</span>
            </label>
            <vs-input
              class="w-full px-2"
              v-model="personalIdentity"
              name="personalIdentity"
              disabled
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="" class="ml-1 vs-input--label">
              Primer nombre
              <span class="text-red-500"> *</span>
            </label>
            <vs-input
              class="w-full px-2"
              v-model.trim="firstName"
              name="firstName"
              disabled
            />
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <vs-input
              class="w-full px-2"
              label="Segundo nombre"
              v-model="middleName"
              name="middleName"
              disabled
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="" class="ml-1 vs-input--label"> Otros nombres </label>
            <vs-input
              class="w-full px-2"
              v-model.trim="extraName"
              name="extraName"
              disabled
            />
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="" class="ml-1 vs-input--label">
              Primer apellido
              <span class="text-red-500"> *</span>
            </label>
            <vs-input
              class="w-full px-2"
              v-model.trim="firstSurname"
              name="firstSurname"
              disabled
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="" class="ml-1 vs-input--label">
              Segundo apellido
              <span class="text-red-500"> *</span>
            </label>
            <vs-input
              class="w-full px-2"
              v-model.trim="secondSurname"
              name="secondSurname"
              disabled
            />
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <vs-input
              class="w-full px-2"
              label="Apellido casada"
              v-model.trim="marriedSurname"
              name="marriedSurname"
              disabled
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="" class="ml-1 vs-input--label">
              Teléfono
              <span class="text-red-500"> *</span>
            </label>
            <vs-input
              class="w-full px-2"
              v-model.trim="phone"
              name="phone"
              disabled
            />
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <vs-input
              class="w-full px-2"
              label="Correo electrónico"
              v-model.trim="email"
              name="email"
              disabled
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="" class="ml-1 vs-input--label">
              Dirección
              <span class="text-red-500"> *</span>
            </label>
            <vs-input
              class="w-full px-2"
              v-model.trim="address"
              name="address"
              disabled
            />
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="" class="ml-1 vs-input--label"> Dirección extra </label>
            <vs-input
              class="w-full px-2"
              v-model.trim="extraAddress"
              name="extraAddress"
              disabled
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <div
              class="vs-component vs-con-input-label vs-input w-full px-2 vs-input-primary"
            >
              <label for="" class="vs-input--label">
                Sexo
                <span class="text-red-500"> *</span>
              </label>
              <div class="vs-con-input">
                <select
                  v-model="genderSelected"
                  class="vs-inputx vs-input--input normal hasValue w-100 bg-white"
                  disabled
                >
                  <option
                    v-for="option in genders"
                    :key="option.id"
                    :value="option.id"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </div>
              <span></span>
            </div>
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <div
              class="vs-component vs-con-input-label vs-input w-full px-2 vs-input-primary"
            >
              <label for="" class="vs-input--label">
                Estado civil
                <span class="text-red-500"> *</span>
              </label>
              <div class="vs-con-input">
                <select
                  v-model="maritalStatusSelected"
                  class="vs-inputx vs-input--input normal hasValue w-100 bg-white"
                  disabled
                >
                  <option
                    v-for="option in maritalStatuses"
                    :key="option.id"
                    :value="option.id"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </div>
              <span></span>
            </div>
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="" class="ml-1 vs-input--label">
              Lugar de trabajo
              <span class="text-red-500"> *</span>
            </label>
            <vs-input
              class="w-full px-2"
              v-model.trim="workName"
              name="workName"
              disabled
            />
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="" class="ml-1 vs-input--label">
              Puesto de trabajo
              <span class="text-red-500"> *</span>
            </label>
            <vs-input
              class="w-full px-2"
              v-model.trim="jobTitle"
              name="jobTitle"
              disabled
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="" class="ml-1 vs-input--label">
              Profesión u oficio
              <span class="text-red-500"> *</span>
            </label>
            <vs-input
              class="w-full px-2"
              v-model.trim="profession"
              name="profession"
              disabled
            />
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="" class="ml-1 vs-input--label">
              Dirección de trabajo
              <span class="text-red-500"> *</span>
            </label>
            <vs-input
              class="w-full px-2"
              v-model.trim="workAddress"
              name="workAddress"
              disabled
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="" class="ml-1 vs-input--label">
              Ingreso mensual
              <span class="text-red-500"> *</span>
            </label>
            <vs-input
              class="w-full px-2"
              v-model.number="monthlyIncome"
              name="monthlyIncome"
              disabled
            />
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="" class="ml-1 vs-input--label">
              Egreso mensual
              <span class="text-red-500"> *</span>
            </label>
            <vs-input
              class="w-full px-2"
              v-model.number="monthlyExpense"
              name="monthlyExpense"
              disabled
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <vs-input
              class="w-full px-2"
              label="Código cliente"
              v-model.trim="reference"
              name="reference"
              disabled
            />
          </div>
        </vs-col>
      </vs-row>

      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2 p-4">
            <p class="text-sm pb-2">Documento frontal</p>
            <div v-if="frontImageUrl">
              <img class="w-100" :src="frontImageUrl.document_url" />
            </div>
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2 p-4">
            <p class="text-sm pb-2">Documento reverso</p>
            <div v-if="backImageUrl">
              <img class="w-100" :src="backImageUrl.document_url" />
            </div>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import { customerApi } from "../../customer/services";
import countryApi from "../../../services/country";
import genderApi from "../../../services/gender";
import maritalStatusApi from "../../../services/maritalStatus";

export default {
  props: [
    "customer",
    "customerIndividual",
    "reservation",
    "updateFn",
    "countryCode",
  ],
  data() {
    return {
      firstName: "",
      middleName: "",
      extraName: "",
      firstSurname: "",
      secondSurname: "",
      marriedSurname: "",
      email: "",
      phone: "",
      taxId: "",
      personalIdentity: "",
      reference: "",
      address: "",
      extraAddress: "",
      frontImageUrl: "",
      backImageUrl: "",
      createFrontImage: false,
      createBackImage: false,
      documentTypeSelected: "",
      documentTypes: [],
      documentTypeLabel: "",
      countries: [],
      countrySelected: null,
      genders: [],
      genderSelected: null,
      maritalStatuses: [],
      maritalStatusSelected: null,
      workName: "",
      jobTitle: "",
      profession: "",
      workAddress: "",
      monthlyIncome: 0,
      monthlyExpense: 0,
    };
  },
  async mounted() {
    const documentPersonal = await customerApi.getDocumentPersonal();
    this.countries = await countryApi.list();
    this.genders = await genderApi.list();
    this.maritalStatuses = await maritalStatusApi.list();

    this.documentTypes = documentPersonal.map((document) => {
      if (document.id === "PERSONAL_DOCUMENT_ID") {
        return {
          name: this.$tc("message.personalIdentityNumber", 1, this.countryCode),
          id: document.id,
        };
      } else return document;
    });

    this.serializeCustomer();
  },
  watch: {
    reservation() {
      this.serializeCustomer();
    },
    documentTypeSelected(value) {
      const documentType = this.documentTypes.find((d) => d.id === value);
      this.documentTypeLabel = documentType.name;
    },
    taxId(value) {
      const re = /[^A-Z0-9]/gi;
      this.$set(this, "taxId", value.replace(re, "").toUpperCase());
    },
  },
  methods: {
    serializeCustomer() {
      this.firstName = this.customerIndividual.first_name;
      this.middleName = this.customerIndividual.middle_name;
      this.firstSurname = this.customerIndividual.first_surname;
      this.secondSurname = this.customerIndividual.second_surname;
      this.marriedSurname = this.customerIndividual.married_surname;
      this.email = this.customer.email;
      this.phone = this.customer.phone;
      this.taxId = this.customer.tax_identification_number;
      this.personalIdentity = this.customerIndividual.personal_identity_number;
      this.reference = this.reservation.client_reference;
      this.address = this.customer.address;
      this.extraAddress = this.customer.address_extra;
      this.countrySelected = this.customer.country_id;
      this.genderSelected = this.customerIndividual.gender_id;
      this.maritalStatusSelected = this.customerIndividual.marital_status_id;
      this.extraName = this.customerIndividual.extra_name;
      this.extraAddress = this.customer.address_extra;
      this.workName = this.customerIndividual.workplace_name;
      this.jobTitle = this.customerIndividual.job_title;
      this.profession = this.customerIndividual.profession;
      this.workAddress = this.customerIndividual.work_address;
      this.monthlyIncome = this.customerIndividual.monthly_income;
      this.monthlyExpense = this.customerIndividual.monthly_expense;

      this.frontImageUrl =
        this.customer.customer_documents.find(
          (doc) => doc.document_type_id == "FRONT_PERSONAL_ID"
        ) || null;

      this.createFrontImage = !this.frontImageUrl ? true : false;

      this.backImageUrl =
        this.customer.customer_documents.find(
          (doc) => doc.document_type_id == "BACK_PERSONAL_ID"
        ) || null;

      this.createBackImage = !this.backImageUrl ? true : false;
      this.documentTypeSelected =
        this.customerIndividual.document_type_id || "PERSONAL_DOCUMENT_ID";
    },
  },
};
</script>
