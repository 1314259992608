<template>
  <div>
    <div class="bg-white shadow rounded-lg p-5">
      <p class="font-semibold">Resumen de unidad {{ property.unit_number }}</p>
      <hr class="pb-2" />
      <div>
        <div class="flex gap-1 pb-2">
          <p class="">Habitaciones:</p>
          <p class="">{{ property.rooms }}</p>
        </div>
        <div class="flex gap-1 pb-2">
          <p class="">{{ $tc("message.Parking", 1, user.countryCode) }}:</p>
          <p class="">({{ assignedParkingsLength() }})</p>
        </div>
        <div class="flex gap-1 pb-2">
          <p class="">{{ $tc("message.Warehouse", 1, user.countryCode) }}:</p>
          <p class="">({{ assignedWarehousesLength() }})</p>
        </div>
        <div class="flex gap-1 pb-2">
          <p class="">Área hab:</p>
          <p class="">{{ property.living_area }}㎡</p>
        </div>
        <div class="flex gap-1 pb-2">
          <p class="">Área const:</p>
          <p class="">{{ property.construction_area }}㎡</p>
        </div>
      </div>
      <div class="mt-4 p-2 bg-gray-100 text-center rounded-full text-white">
        <router-link :to="`/apartamento/${property.id}`">
          <p class="font-semibold text-sm">Ver más detalle de unidad</p>
        </router-link>
      </div>
      <div class="mt-2 p-2 bg-gray-100 text-center rounded-full text-gray">
        <a role="button" href="#" @click="openQuote">
          <p class="font-semibold text-sm">Cotización generada</p>
        </a>
      </div>
      <div class="mt-2 p-2 bg-gray-100 text-center rounded-full text-gray">
        <a role="button" href="#" v-on:click="sendStatement">
          <p class="font-semibold text-sm">Enviar estado de cuenta</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { email } from "../../../services";

const HANDLER_URL = process.env.VUE_APP_HANDLER_URL;

export default {
  props: ["property", "reservation"],
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    assignedWarehousesLength() {
      const warehouses = this.property.assigned_properties.filter(
        (p) => p.property.property_type_id === "WAREHOUSE"
      );

      return warehouses.length;
    },
    assignedParkingsLength() {
      const parkings = this.property.assigned_properties.filter(
        (p) => p.property.property_type_id === "PARKING"
      );

      return parkings.length;
    },
    openQuote() {
      const pdfUrl = `${HANDLER_URL}/quote/${this.reservation.quote.id}/pdf`;
      window.open(pdfUrl, "_blank");
    },
    sendStatement() {
      email.sendEmail(
        "statement",
        `{"reservationId": "${this.reservation.id}"}`
      );
      this.$vs.notify({
        title: "Estado de cuenta enviado correctamente",
        text: `Estado de cuenta enviado correctamente.`,
        color: "success",
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
