<template>
  <div>
    <div class="bg-white shadow rounded-lg p-5">
      <p class="font-semibold">Resumen de pagos</p>
      <hr class="pb-2" />
      <vs-row>
        <vs-col vs-type="flex" vs-w="6">
          <div>
            <p class="text-semibold">
              Total de {{ $tc("message.reservation", 1, this.countryCode) }}:
            </p>
            <p class="font-semibold mt-1 text-gray-500">
              {{ getFormatCurrency(reservation.quote.downpayment) }}
            </p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
          <PercentComponent
            :downpayments="downpayments"
            :reservation="reservation"
          />
        </vs-col>
      </vs-row>

      <vs-row class="mt-1">
        <vs-col vs-type="flex" vs-w="6">
          <div>
            <p class="text-semibold">
              Cantidad de pagos:
            </p>
            <p class="font-semibold text-gray-500">
              {{ reservation.quote.downpayment_installments }}
            </p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
        </vs-col>
      </vs-row>

      <vs-row class="mt-1">
        <vs-col vs-type="flex" vs-w="6">
          <div>
            <p class="text-semibold">
              Monto pagado:
            </p>
            <p class="font-semibold mt-1 text-green-500">
              {{ getFormatCurrency(paid()) }}
            </p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
          <div>
            <p class="text-semibold mt-1">
              Monto pendiente:
            </p>
            <p class="font-semibold mt-1 text-red-400">
              {{ getFormatCurrency(pending()) }}
            </p>
          </div>
        </vs-col>
      </vs-row>

      <div
        class="mt-4 p-2 bg-green-400 text-center rounded-full text-white"
        v-if="reservation.payment_status.id != 'DELAYED'"
      >
        <p class="font-semibold text-sm">
          {{ reservation.payment_status.name }}
        </p>
      </div>
      <div
        class="mt-4 p-2 bg-red-400 text-center rounded-full mx-10 mt-3 text-white"
        v-else
      >
        <p class="font-semibold text-sm">
          {{ reservation.payment_status.name }}
        </p>
      </div>

      <div class="mt-2 p-2 bg-gray-100 text-center rounded-full text-gray">
        <a role="button" @click="openDocumentPopup">
          <p class="font-semibold text-sm">
            Ver documento de
            {{ $tc("message.reservation", 1, this.countryCode) }}
          </p>
        </a>
      </div>

      <div class="mt-2 p-2 bg-gray-100 text-center rounded-full text-gray">
        <a role="button" @click="openStatement">
          <p class="font-semibold text-sm">
            Estado de Cuenta
          </p>
        </a>
      </div>
    </div>

    <vs-popup
      :title="`Documento de ${$tc('message.reservation', 1, this.countryCode)}`"
      :active.sync="documentUpdatePopUp"
    >
      <UpdateDocumentPopup
        :reservation="reservation"
        :countryCode="countryCode"
        :updateFn="updateFn"
      />
    </vs-popup>
  </div>
</template>

<script>
import currencies from "../../../helpers/currencies";
import PercentComponent from "./PercentComponent.vue";
import UpdateDocumentPopup from "./UpdateDocumentPopup.vue";

const HANDLER_URL = process.env.VUE_APP_HANDLER_URL;

export default {
  props: ["downpayments", "reservation"],
  components: {
    PercentComponent,
    UpdateDocumentPopup
  },
  computed: {
    countryCode() {
      return this.reservation.quote.project.city.country.code;
    }
  },
  data() {
    return {
      documentUpdatePopUp: false
    };
  },
  methods: {
    getFormatCurrency(value) {
      const currencySymbol = this.$store.getters["project/currencySymbol"];

      return currencies.currencyFormatter({ value, currency: currencySymbol });
    },
    paid() {
      let totalPaid = this.downpayments.filter(
        d => d.reservation_downpayment_payments.length > 0
      );

      if (!totalPaid.length) return 0;

      totalPaid = totalPaid
        .map(p => {
          return p.reservation_downpayment_payments.reduce(
            (accum, last) => accum + (!last.reservation_payment.canceled ? last.amount : 0),
            0
          );
        })
        .reduce((accum, last) => accum + last);

      return totalPaid;
    },
    pending() {
      const totalDownpayment = this.downpayments.reduce(
        (prev, last) => prev + last.amount,
        0
      );
      return totalDownpayment - this.paid();
    },
    openDocumentPopup() {
      this.documentUpdatePopUp = true;
    },
    updateFn(value) {
      this.documentUpdatePopUp = !value;
    },
    openStatement() {
      const pdfUrl = `${HANDLER_URL}/reservation/${this.reservation.id}/statement/pdf`;
      window.open(pdfUrl, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped></style>
